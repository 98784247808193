'use client';

import Config from '@/config';
import { ApolloLink, HttpLink, gql } from '@apollo/client';
import {
  ApolloNextAppProvider,
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';

function makeClient() {
  const httpLink = new HttpLink({
    uri: Config.GRAPHQL_URL,
  });

  return new NextSSRApolloClient({
    typeDefs: gql`
      enum MenuLocationEnum {
        FOOTER_NAVIGATION
        PRIMARY_NAVIGATION
        USER_NAVIGATION
      }
    `,
    cache: new NextSSRInMemoryCache(),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLink,
          ])
        : httpLink,
  });
}

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
