'use client';

import Config from '@/config';
import SettingsContext, {
  SettingsContextValue,
  defaultValue,
} from '@/contexts/settingsContext';
import { fetchApi } from '@/helpers/api';
import { useEffect, useState } from 'react';

export default function SettingsWrapper(props: any) {
  const { children } = props;

  const [settings, setSettings] = useState<SettingsContextValue>(defaultValue);

  useEffect(() => {
    async function getData() {
      let response: Response | null = null;

      try {
        response = await fetchApi(
          `${Config.COMPANY_WEBSITE}/wp-json/options/all`,
        );
      } catch (error: any) {
        console.error(error);
      }

      const value = response && response.ok ? await response.json() : null;

      if (value) {
        setSettings(value);
      }
    }

    getData();
  }, []);

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
}
